import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      showModal: false,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      name: '',
      email: '',
      message: '',
      showModal: true,
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <section className="gazette-contact-area section_padding_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <form
                name="contact"
                onSubmit={this.handleSubmit}
                data-netlify="true"
                data-netlify-honeypot="bot"
                overlay={this.state.showModal}
                onClick={this.closeModal}
              >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Ne pas remplir:{' '}
                    <input name="bot" onChange={this.handleInputChange} />
                  </label>
                </p>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Votre Nom"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    placeholder="Message"
                    value={this.state.message}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
                <div data-netlify-recaptcha />
                <button type="submit" name="submit" className="btn contact-btn">
                  ENVOYER <i className="fa fa-angle-right ml-2" />
                </button>
                {this.state.showModal && (
                  <div className="alert alert-primary fade show" role="alert">
                    <p>
                      Merci pour votre message. Nous vous répondrons dès que
                      possible.{' '}
                      <button onClick={this.closeModal}>Fermer</button>
                    </p>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

ContactForm.propTypes = {
  data: PropTypes.object,
}

export default ContactForm
