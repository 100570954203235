import React from 'react'
import Helmet from 'react-helmet'

import config from '../utils/siteConfig'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
import ContactForm from '../components/ContactForm'

const ContactPage = () => {
  const post = {
    title: 'Contact',
  }

  return (
    <Layout>
      <Helmet>
        <title>{`${post.title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={post} pagePath="contact" customTitle />
      <Breadcrumb category={post.title} />
      <ContactForm />
    </Layout>
  )
}

export default ContactPage
